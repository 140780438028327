import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { UsuarioService } from "../usuario/usuario.service";

@Injectable()
export class RegistroGuard implements CanActivate {
  public privileges: any;

  constructor(public usuarioService: UsuarioService, public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let authorization = this.usuarioService.esAdmin();
    const privileges = route.data["privileges"];

    if (privileges) {
      for (const priv of privileges) {
        authorization = authorization || this.hasPrivilege(priv);
        // console.log(authorization);
      }
    }

    if (authorization) {
      return true;
    }

    this.usuarioService.logout();
    return false;
  }

  hasPrivilege(key: string): boolean {
    this.privileges = JSON.parse(localStorage.getItem("accesos"));
    if (!this.privileges.datos) {
      return false;
    }
    return (
      this.privileges.datos.filter((p) => p.modulo === key && p.nivel > 1)
        .length > 0
    );
  }
}
