import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { UsuarioService } from "../usuario/usuario.service";
import { tap } from "rxjs/operators";

@Injectable()
export class LoginGuard implements CanActivate {
  constructor(public usuarioService: UsuarioService, public router: Router) {}

  canActivate() {
    if (
      this.usuarioService.estaLogueado() &&
      this.usuarioService.esColaborador()
    ) {
      // console.log("Paso el Login Guard");
      return this.usuarioService.validarToken().pipe(
        tap((estaAutenticado) => {
          if (!estaAutenticado) {
            this.usuarioService.logout();
            this.router.navigate(["/login"]);
          }
          console.log("Autenticado y renovo token", estaAutenticado);
        })
      );
    } else {
      // console.log("Bloqueado por Guard");
      this.router.navigate(["/login"]);
      return false;
    }
  }
}
