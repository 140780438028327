import { Component, ViewEncapsulation } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
declare var gtag;

@Component({
  selector: "az-root",
  encapsulation: ViewEncapsulation.None,
  template: `<router-outlet></router-outlet>`,
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  constructor(public router: Router) {
    const navEndEvents$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    );

    navEndEvents$.subscribe((event: NavigationEnd) => {
      gtag("config", "G-4DD18644MQ", {
        page_path: event.urlAfterRedirects,
      });
    });
  }
}
