import "pace";
import { BrowserModule } from "@angular/platform-browser";
import { LOCALE_ID, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AgmCoreModule } from "@agm/core";
import { AppRoutingModule } from "./app.routing";
import { AppConfig } from "./app.config";
import { AppComponent } from "./app.component";
import { ErrorComponent } from "./pages/error/error.component";
import { ToastrModule } from "ngx-toastr";

import { HttpClientModule } from "@angular/common/http";

/** Fecha en español **/
import localeEs from "@angular/common/locales/es";
import { registerLocaleData } from "@angular/common";
registerLocaleData(localeEs);

/** Servicios **/
import { ServiceModule } from "./theme/services/service.module";
import { PopoverModule } from "ngx-bootstrap/popover";

@NgModule({
  declarations: [AppComponent, ErrorComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyAO7Mg2Cs1qzo_3jkKkZAKY6jtwIlm41-I",
    }),
    ToastrModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ServiceModule,
    PopoverModule.forRoot(),
  ],
  providers: [AppConfig, { provide: LOCALE_ID, useValue: "es" }],
  bootstrap: [AppComponent],
})
export class AppModule {}
