import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { API_REGATAS, API_SERVICIOS } from "../../../config/config";

import { Tramite } from "../../../models/tramite.model";
import { Incidencia } from "../../../models/incidencia.model";
import { Seguimiento } from "../../../models/seguimiento.model";

import { UsuarioService } from "../usuario/usuario.service";

import { Observable, throwError } from "rxjs";
import { catchError, retry, map } from "rxjs/operators";

@Injectable()
export class IncidenciaService {
  categoria = [
    {
      codigo: "001",
      titulo: "Trámites de registro y estado civil",
      area: "Registro de Asociados",
    },
    // {
    //   codigo: "002",
    //   titulo: "Trámites de emisión de carnés",
    //   area: "Registro de Asociados",
    // },
    // {
    //   codigo: "003",
    //   titulo: "Trámites de condición o categoría de asociado",
    //   area: "Registro de Asociados",
    // },
    {
      codigo: "004",
      titulo: "Trámites de refinanciamiento de cuota ordinaria",
      area: "Créditos y Cobranzas",
    },
    {
      codigo: "005",
      titulo: "Trámites de refinanciamiento de cuota ingreso",
      area: "Créditos y Cobranzas",
    },
  ];

  tipo = [
    // {
    //   codigo: "001-01",
    //   titulo: "Registro de nuevos asociados (por postulación)",
    //   categoria: "001",
    // },
    // {
    //   codigo: "001-02",
    //   titulo: "Actualización de datos personales de asociados o familiares",
    //   categoria: "001",
    // },
    // {
    //   codigo: "001-03",
    //   titulo: "Inscripción de cónyuges de asociado",
    //   categoria: "001",
    // },
    // {
    //   codigo: "001-04",
    //   titulo: "Inscripción de nacimientos de hijos de asociado",
    //   categoria: "001",
    // },
    // {
    //   codigo: "001-05",
    //   titulo: "Inscripción de fallecimientos de asociados y familiares",
    //   categoria: "001",
    // },
    // {
    //   codigo: "001-06",
    //   titulo: "Inscripción de divorcio de asociados",
    //   categoria: "001",
    // },
    // { codigo: "001-07", titulo: "Viudas(os) de asociados", categoria: "001" },
    // {
    //   codigo: "001-08",
    //   titulo: "Trámite para inscribir a hijas divorciadas o viudas",
    //   categoria: "001",
    // },
    // {
    //   codigo: "001-09",
    //   titulo: "Trámite para inscribir hijas de asociado fallecido",
    //   categoria: "001",
    // },
    // {
    //   codigo: "001-10",
    //   titulo: "Trámite para inscribir a nietos de asociados",
    //   categoria: "001",
    // },
    // {
    //   codigo: "001-11",
    //   titulo: "Trámite para inscribir a madres de asociados",
    //   categoria: "001",
    // },
    // {
    //   codigo: "001-12",
    //   titulo: "Trámite para inscribir a hijos de cónyuge",
    //   categoria: "001",
    // },
    // {
    //   codigo: "001-13",
    //   titulo: "Trámite para inscribir a hermanas solteras",
    //   categoria: "001",
    // },
    {
      codigo: "001-14",
      titulo: "Solicitud de carné de pareja estable y permanente",
      categoria: "001",
    },
    {
      codigo: "001-15",
      titulo: "Solicitud de pase virtual de invitado",
      categoria: "001",
    },
    // {
    //   codigo: "001-16",
    //   titulo: "Carné para hijos excepcionales",
    //   categoria: "001",
    // },
    // {
    //   codigo: "001-17",
    //   titulo: "Trámite para asociados transeúntes por un mes",
    //   categoria: "001",
    // },
    {
      codigo: "002-01",
      titulo: "Renovación de carné de asociado",
      categoria: "002",
    },
    {
      codigo: "002-02",
      titulo: "Renovación de carné de familiar",
      categoria: "002",
    },
    {
      codigo: "002-03",
      titulo: "Emisión de fotocheck de personal de servicio",
      categoria: "002",
    },
    { codigo: "002-04", titulo: "Duplicados de carnés", categoria: "002" },
    { codigo: "003-01", titulo: "Asociado ausente", categoria: "003" },
    {
      codigo: "003-02",
      titulo: "Asociado activo (por regreso definitivo al país)",
      categoria: "003",
    },
    {
      codigo: "003-03",
      titulo: "Autorización provisional de ingreso para ausentes",
      categoria: "003",
    },
    { codigo: "003-04", titulo: "Vitalicios", categoria: "003" },
    { codigo: "003-05", titulo: "Pre Vitalicios", categoria: "003" },
    { codigo: "003-06", titulo: "Renuncias", categoria: "003" },
    {
      codigo: "003-07",
      titulo: "Inactividad por matrimonio con otro asociado",
      categoria: "003",
    },
    {
      codigo: "004-01",
      titulo: "FORMATO C REFINANCIAMIENTO CUOTAS ORDINARIAS",
      categoria: "004",
    },
    {
      codigo: "004-02",
      titulo: "FORMATO D REFINANCIAMIENTO CUOTAS ORDINARIAS",
      categoria: "004",
    },
    {
      codigo: "004-03",
      titulo: "FORMATO E REFINANCIAMIENTO CUOTAS ORDINARIAS",
      categoria: "004",
    },
    {
      codigo: "005-01",
      titulo: "REFINANCIAMIENTO CUOTA DE INGRESO",
      categoria: "005",
    },
  ];

  constructor(
    public http: HttpClient,
    private usuarioService: UsuarioService
  ) {}

  public getCategoria(): Object {
    return this.categoria;
  }

  public getTipo(): Object {
    return this.tipo;
  }

  getGrupoAsociado(Carnet: string): Observable<any> {
    const url = API_SERVICIOS + "pool/membresia/";
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.get(url + Carnet, { headers: headers });
  }

  postIncidencia(dato: Incidencia): Observable<any> {
    const params = JSON.stringify(dato);
    const url = API_REGATAS + "api/guardar-incidencia";
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(url, params, { headers: headers });
  }

  patchIncidencia(dato: Incidencia): Observable<any> {
    const params = JSON.stringify(dato);
    const url = API_REGATAS + "api/actualizar-incidencia/";
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.patch(url + dato._id, params, { headers: headers });
  }

  getIncidencia(modulo: number): Observable<any> {
    const url = API_REGATAS + "api/incidencia/";
    return this.http.get(url + modulo, this.usuarioService.headers);
  }

  getIncidencias(): Observable<any> {
    const url = API_REGATAS + "api/incidencias";
    return this.http.get(url, this.usuarioService.headers);
  }

  postSeguimiento(dato: Seguimiento): Observable<any> {
    const params = JSON.stringify(dato);
    const url = API_REGATAS + "api/guardar-seguimiento";
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(url, params, { headers: headers });
  }

  patchSeguimiento(dato: Seguimiento): Observable<any> {
    const params = JSON.stringify(dato);
    const url = API_REGATAS + "api/actualizar-seguimiento/";
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.patch(url + dato._id, params, { headers: headers });
  }

  getSeguimiento(codigo: number): Observable<any> {
    const url = API_REGATAS + "api/seguimiento/";
    return this.http.get(url + codigo, this.usuarioService.headers);
  }

  postTramite(dato: Tramite): Observable<any> {
    const params = JSON.stringify(dato);
    const url = API_REGATAS + "api/guardar-tramite";
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(url, params, { headers: headers });
  }

  patchTramite(dato: Tramite): Observable<any> {
    const params = JSON.stringify(dato);
    const url = API_REGATAS + "api/actualizar-tramite/";
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.patch(url + dato._id, params, { headers: headers });
  }

  getTramite(codigo: number): Observable<any> {
    const url = API_REGATAS + "api/tramite/";
    return this.http.get(url + codigo, this.usuarioService.headers);
  }

  subirPDF(datos: any): Observable<any> {
    const urlphp = "https://regataslima.pe/upload-pdf-regatas.php";
    return this.http.post(urlphp, datos);
  }

  getCanales(): Observable<any> {
    const url = API_REGATAS + "api/canales";
    return this.http.get(url, this.usuarioService.headers);
  }
}
