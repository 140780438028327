import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import {
  UsuarioService,
  ExcelService,
  IncidenciaService,
  LoginGuard,
  VisorGuard,
  RegistroGuard,
  ControlGuard,
  SupervisorGuard,
  BlogGuard,
  MembresiaGuard,
} from "./service.index";

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    UsuarioService,
    ExcelService,
    IncidenciaService,
    LoginGuard,
    VisorGuard,
    RegistroGuard,
    ControlGuard,
    SupervisorGuard,
    BlogGuard,
    MembresiaGuard,
  ],
  declarations: [],
})
export class ServiceModule {}
