import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { API_REGATAS, API_SHARED } from "../../../config/config";

import { Observable, throwError, of } from "rxjs";
import { tap, catchError, retry, map } from "rxjs/operators";

import { Router } from "@angular/router";
import { Login } from "../../../models/login.model";
import { Usuario } from "../../../models/usuario.model";
import { Acceso } from "../../../models/usuario-acceso.model";

declare const swal: any;

@Injectable({
  providedIn: "root",
})
export class UsuarioService {
  public perfil: any;
  public token: string;

  constructor(private http: HttpClient, public router: Router) {
    this.cargarStorage();
  }

  get headers() {
    return {
      headers: {
        "x-token": this.token,
      },
    };
  }

  estaLogueado() {
    if (localStorage.getItem("token")) {
      return localStorage.getItem("token").length > 5 ? true : false;
    } else {
      return false;
    }
  }

  esAdmin(): boolean {
    if (this.perfil.role === "SUPER_ADMIN_ROLE") {
      return true;
    } else {
      return false;
    }
  }

  esColaborador(): boolean {
    if (
      this.perfil.role === "COLABORADOR_ROLE" ||
      this.perfil.role === "ADMIN_ROLE" ||
      this.perfil.role === "SUPER_ADMIN_ROLE"
    ) {
      return true;
    } else {
      return false;
    }
  }

  esRRHH(): boolean {
    if (this.perfil.role === "RRHH_ROLE") {
      return true;
    } else {
      return false;
    }
  }

  cargarStorage() {
    if (localStorage.getItem("token")) {
      this.token = localStorage.getItem("token");
      this.perfil = JSON.parse(localStorage.getItem("usuario"));
    } else {
      this.token = "";
      this.perfil = null;
    }
  }

  guardarStorage(token: string, usuario: any) {
    localStorage.setItem("token", token);
    localStorage.setItem("usuario", JSON.stringify(usuario));

    this.token = token;
    this.perfil = usuario;
  }

  logout() {
    this.token = "";
    this.perfil = null;

    localStorage.removeItem("token");
    localStorage.removeItem("usuario");
    localStorage.removeItem("accesos");

    this.router.navigate(["/login"]);
  }

  login(dato: Login, recordar: boolean = false): Observable<any> {
    if (recordar) {
      localStorage.setItem("username", dato.username);
    } else {
      localStorage.removeItem("username");
    }

    const url = API_REGATAS + "api/login";

    return this.http
      .post(url, dato, {
        headers: {
          "x-api-key":
            "$2a$10$nFuhGsI/9baZLu5C7TsAQuHgF5TiJ7A6C3NkT/qLenmg4jGJlZ2Sq",
        },
      })
      .pipe(
        map((resp: any) => {
          if (
            resp.usuario.status &&
            (resp.usuario.role === "COLABORADOR_ROLE" ||
              resp.usuario.role === "ADMIN_ROLE" ||
              resp.usuario.role === "SUPER_ADMIN_ROLE")
          ) {
            this.guardarStorage(resp.token, resp.usuario);
            return true;
          } else if (
            !resp.usuario.status ||
            (resp.usuario.role !== "COLABORADOR_ROLE" &&
              resp.usuario.role !== "ADMIN_ROLE" &&
              resp.usuario.role !== "SUPER_ADMIN_ROLE")
          ) {
            swal("Error", "Usuario deshabilitado", "error");
            return false;
          }
        })
      );
  }

  accesosUser(idUsuario: string): Observable<any> {
    const url = API_REGATAS + "api/acceso/";
    return this.http.get(url + idUsuario, this.headers);
  }

  accesosAll(): Observable<any> {
    const url = API_REGATAS + "api/accesos";
    return this.http.get(url, this.headers);
  }

  StatusColaborador(dni: string): Observable<any> {
    const url = API_SHARED + "StatusColaborador/";
    return this.http.get(url + dni, this.headers);
  }

  RecuperarPass(email: string): Observable<any> {
    const url = API_REGATAS + "api/recoverpass/";
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.get(url + email, { headers: headers });
  }

  ResetearPass(dato: any): Observable<any> {
    const params = JSON.stringify(dato);
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("x-token", this.token);
    return this.http.patch(
      `${API_REGATAS}api/resetpass/${dato.userId}`,
      params,
      { headers }
    );
  }

  patchUsuario(dato: Usuario): Observable<any> {
    const params = JSON.stringify(dato);
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("x-token", this.token);
    return this.http.patch(`${API_REGATAS}api/usuario/${dato._id}`, params, {
      headers,
    });
  }

  postUsuario(dato: Usuario): Observable<any> {
    const params = JSON.stringify(dato);
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("x-token", this.token);
    return this.http.post(`${API_REGATAS}api/save-usuario`, params, {
      headers,
    });
  }

  /** Revisar metodo, ya que en RRHH valida las solicitudes por DNI */
  getUsuario(codigo: string): Observable<any> {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("x-token", this.token);
    return this.http.get(`${API_REGATAS}api/usuario/${codigo}`, {
      headers,
    });
  }

  deleteAcceso(idUsuario: string) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");

    return this.http
      .delete(`${API_REGATAS}api/eliminar-acceso/${idUsuario}`, { headers })
      .pipe(
        map((res) => {
          return res;
        }),
        retry(1),
        catchError(this.handleError)
      )
      .toPromise();
  }

  postAcceso(acceso: Acceso[]) {
    const params = JSON.stringify(acceso);
    const headers = new HttpHeaders().set("Content-Type", "application/json");

    return this.http
      .post(`${API_REGATAS}api/insertar-acceso`, params, { headers })
      .pipe(
        map((res) => {
          return res;
        }),
        retry(1),
        catchError(this.handleError)
      )
      .toPromise();
  }

  validarToken(): Observable<any> {
    return this.http
      .get(`${API_REGATAS}api/renew-user`, {
        headers: {
          "x-token": this.token,
        },
      })
      .pipe(
        map((resp: any) => {
          if (
            resp.usuario.status &&
            (resp.usuario.role === "COLABORADOR_ROLE" ||
              resp.usuario.role === "ADMIN_ROLE" ||
              resp.usuario.role === "SUPER_ADMIN_ROLE")
          ) {
            this.perfil = resp.usuario;
            this.guardarStorage(resp.token, resp.usuario);
            return true;
          } else if (
            !resp.usuario.status ||
            (resp.usuario.role !== "COLABORADOR_ROLE" &&
              resp.usuario.role !== "ADMIN_ROLE" &&
              resp.usuario.role !== "SUPER_ADMIN_ROLE")
          ) {
            swal("Error", "Usuario deshabilitado", "error");
            return false;
          }
        }),
        catchError((error) => of(false))
      );
  }

  private handleError(error: Response | any) {
    // this.logout();
    // this.router.navigate(["/login"]);
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.error.message}`;
    }
    return throwError(errorMessage);
  }
}
